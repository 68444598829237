.doctor-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
}

.doctor-profile {
  width: 300px;

  img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px;
  }
  margin-right: 10px;
}

.doctor-about {
  font-size: 1.4em;
  line-height: 1.7;
  flex: 1;
  min-width: 300px;
  padding: 0 20px 20px 20px;
}

.doctor-about .text-highlight {
  margin: 6px;
}

.profile-image-overlay {
  position: relative;
  overflow: hidden;
}

.profile-details {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  text-align: center;
  width: 100vw;
}

.profile-details h2 {
  margin: 0;
  font-size: 18px;
  color: black;
}

.profile-details p {
  margin: 5px 0 0 0;
  font-size: 14px;
  color: grey;
}
