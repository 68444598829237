.service-card-image {
  min-height: 300px !important;
  object-fit: cover;
}
.service-category-divider {
  padding: 10px 0;
  font-size: 18px;

  h2 {
    font-size: 18px;
    font-family: "Roboto", sans-serif !important;
  }
}

.link {
  margin: 10px;
  padding: 10px;
  font-size: 18px;
  font-weight: 300;
  background-color: #f1f1f1;
  color: #333;
  cursor: pointer;
}

.link.active {
  background-color: #333;
  color: #fff;
}

@media (max-width: 608px) {
  .link {
    font-size: 14px;
    margin: 5px;
    padding: 5px;
  }
}

.secondary-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sticky-menu {
  position: -webkit-sticky;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  width: 100%;
}

/* Define the sticky state */
.sticky {
  position: fixed;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.hide {
  display: none;
}
