.home-main-container {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.home-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.home-hero-container {
  background-image: url("/public/imgs/home-bg-new.webp");
  background-size: cover;
  background-position: center;
  padding: 100px;
  text-align: center;
  color: #fff;
  overflow: hidden;
  width: 100%;
  height: 400px;
  border-radius: 35% 15%;
}

@media (max-width: 680px) {
  .home-hero-container {
    padding: 10px;
  }
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}
.hero h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-shadow: -1px 1px 20px rgb(0 0 0);
}

.hero h1 {
  font-size: 68px;
  text-align: center;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  opacity: 95%;
  color: #333;
}

@media (max-width: 680px) {
  .hero-title {
    width: 110%;
    background-color: #fff;
    opacity: 96%;
  }
  .hero h1 {
    font-size: 40px;
  }
}

.hero .book-button {
  background-color: #ffd700;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 18px;
}

.hero p {
  font-size: 16px;
}

.welcome-message {
  font-size: 1.4em;
  /* font-weight: 400; */
  /* line-height: 1.9; */
  padding: 20px 10px;
  margin: 10px 0;
  background-color: #fff;
}

.home-map-container {
  flex-grow: 1;

  iframe {
    border: 0px;
    height: 300px;
    width: 100%;
  }
}

.hours-container {
  display: flex;
  flex-direction: column;

  .day-and-hours {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  display: flex;
  .days,
  .hours {
    /* padding: 10px; */
    /* border: 1px solid #f1f1f1; */
    /* border-radius: 5px; */
    margin-right: 10px;
  }

  .hours {
    font-weight: 400;
  }
}

.insurance-logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.insurance-logo {
  flex: 0 0 calc(33.333% - 10px);
  min-width: 0;
  max-width: 180px;
  height: auto;
  object-fit: contain;
  padding: 8px;
}

@media (max-width: 680px) {
  .insurance-logos-container {
    gap: 10px;
    padding: 5px;
  }

  .insurance-logo {
    flex: 0 0 calc(33.333% - 7px);
    padding: 5px;
  }
}
