.contact-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contact-form {
  background-color: #f2f2f2;
  padding: 50px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.contact-form > .blurb {
  font-size: 20px;
  padding-bottom: 50px;
}

.contact-container > .divider {
  font-weight: 300 !important;
}

.contact-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
}

.contact-cards-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 0 10px;
  background-color: #f2f2f2;
  border-radius: 2px;
  cursor: pointer;
}

.contact-cards-card > .content {
  font-size: 1.3em;
  padding-left: 5px;
  a {
    color: #333;
  }
}

.contact-cards-card .fa-solid {
  font-size: 3em; /* fa-4x equivalent */
}

@media screen and (max-width: 650px) {
  .contact-form {
    flex-grow: 1;
    padding: 20px;
    width: 100%;
  }

  .contact-cards-card {
    padding: 1px 10px;
    margin-top: 5px;
    width: 100%;
  }

  .contact-cards-card > .content {
    font-size: 16px;
  }

  .contact-cards-card .fa-solid {
    font-size: 2em; /* fa-4x equivalent */
  }
}
