.menu-header {
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.sidebar-menu {
  overflow: hidden;
}

.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}

.menu-item a {
  text-align: right !important;
  flex-grow: 1;
  flex: 1;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.logo-container {
  display: flex;
  align-items: center;
  /* background-color: rgba(173, 138, 219, 0.8); */
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}

.logo-img {
  width: 200px;
  margin: 0;
  padding: 0;
}

.logo {
  font-size: 24px;
  font-weight: 200;
  margin: 0;
  /* color: white; */
}

.hamburger-menu-icon {
  display: none;
  cursor: pointer;
}

.full-menu {
  display: block;
}

@media (max-width: 680px) {
  .hamburger-menu-icon {
    display: block;
  }
  .full-menu {
    display: none;
  }

  .fa-solid {
    padding: 0.4em;
  }
}

.contact-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.book-button {
  background-color: #ffd700;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}
