html,
body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300;
  font-style: normal;
  margin: 0;
  padding: 0;
  font-size: 16px;
  max-width: 1000px;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.95);
}
body {
  overflow-y: scroll;
}

main {
  flex: 1;
  padding: 0 15px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out;
}
